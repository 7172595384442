<template>
    <div class="o-account-action">
        <spin-loader class="c-spin-loader-overlay"
            :version="2"
            v-show="loading"
        >
        </spin-loader>
        <div class="o-account-action__header" v-if="header">
            {{ header }}
        </div>
        <div class="o-account-action__sub" v-if="sub">
            {{ sub }}
        </div>
        <div class="o-account-action__main">
            <slot></slot>
        </div>
        <div class="o-account-action__wrap" v-if="showButtons">
            <component
                class="o-account-action__cancel o-account-action__button border-button u-primary-color__border u-primary-color__text u-hover--grow"
                v-if="buttons && buttons.cancel"
                @click="$emit('close')"
                v-bind="buttonProps('cancel')"
                :is="buttonTag('cancel')"
            >
                {{ buttonText('cancel') }}
            </component>
            <component
                class="o-account-action__cancel o-account-action__button border-button u-primary-color__border u-primary-color__text u-hover--grow"
                v-if="buttons && buttons.other"
                @click="$emit('other')"
                v-bind="buttonProps('other')"
                :is="buttonTag('other')"
            >
                {{ buttonText('other') }}
            </component>
            <component class="lowercase-button o-account-action__button u-primary-color__bg u-primary-color__border u-hover--grow"
                v-if="buttons && buttons.action"
                @click="$emit('action')"
                v-bind="buttonProps('action')"
                :is="buttonTag('action')"
            >
                {{ buttonText('action') }}
            </component>
        </div>
        <div class="o-account-action__wrap" v-else>
            <slot name="bottom"></slot>
        </div>
    </div>
</template>

<script>
export default {
    emits: ["action", "close", "other"],
    props: {
        buttons: Object,
        header: String,
        sub: String,
        showButtons: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        href: String
    },
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {
        buttonText(button) {
            return this.buttons[button].text || this.buttons[button];
        },
        buttonProps(button) {
            return this.buttons[button].props || {};
        },
        buttonTag(button) {
            return this.buttons[button].tag || 'div';
        },
    }
}
</script>

<style lang="scss" scoped>
    .o-account-action {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px;
        position: relative;

        &__header {
            font-weight: 600;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
        }

        &__main {
            flex-grow: 1;
            margin-bottom: 20px;
        }

        &__sub {
            margin-bottom: 10px;
        }

        &__wrap {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
        }

        &__button {
            font-size: 16px;
            justify-content: center;
            min-width: 160px;
            padding: 6px 20px;
            &:hover {
                color: #fff;
            }
        }

        &__cancel {
            margin-right: 20px;
        }

         @media (max-width: 650px) {

            &__wrap {
                flex-direction: column;
                justify-content: none;
            }

            &__cancel {
                margin: 0 0 20px 0;
            }
        }
    }
</style>
