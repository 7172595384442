import { DEFAULT_CURRENCY } from '@js/core/currencies.js';
import stats from './stats.js';
import settings from './settings.js';
import billing from './billing.js';
import profile from './profile.js';
import connections from './connections.js';
import notifications from '@js/store/modules/notifications.js';
import { Payload } from 'vuex-pathify';
import nalRequest from '@js/core/nalRequest.js';

export default {
    namespaced: true,
    modules: {
        stats,
        notifications,
        settings,
        billing,
        profile,
        connections,
    },
    state: {
        user: null,
    },
    getters: {
        apiUrl: (state, getters) => (resource, id = '') => {
            return `api/executive/${state.user.id}/${resource}/${id || ''}`;
        },
        fullname(state) {
            return state.user && state.user.details.fullName;
        },
        honorific(state) {
            return state.user && state.user.details.honorific;
        },
        initials(state) {
            return state.user && state.user.details.fullName.split(' ').map(name => name[0]).join('').slice(0, 2);
        },
        primaryTitle(state) {
            return state.user && state.user.details.title;
        },
        profilePicture(state) {
            return state.user && state.user.details.profile_picture;
        },
        planType(state) {
            return state.billing.type;
        },
        isUpgraded(state) {
            return !!(state.billing && (state.billing.platinum || state.billing.premium));
        },
        getEmailForm(state) {
            return new Form({ email: state.user ? state.user.details.accountEmail : '' });
        },
        getPhoneForm(state) {
            return new Form({ phone: state.user ? state.user.details.accountPhone : '' });
        },
        getPasswordCheckForm(state) {
            return new Form({ password: '' });
        },
        getPasswordResetForm(state) {
            return new Form({ old_password: '', password: '', password_confirmation: '', linkedinId: '', password_set: true });
        },
        has2faEnabled(state) {
            return state.user ? state.user.has2faEnabled : false;
        },
    },
    mutations: {
        SET_USER(state, payload) {
            if (payload instanceof Payload) {
                state.user = payload.update(state.user);
            } else {
                let user = payload;
                // To limit API calls we can load some things on the user model
                // but then we need to defer them to the appropriate modules
                // and remove them from the user object to deter bad practices.
                this.commit('user/billing/SET_BILLING', user.billing);
                delete user.billing;
                let settings = user.settings;
                delete user.settings;
                state.user = user;
                this.commit('user/settings/SET_CURRENCY', settings ? settings.currency : DEFAULT_CURRENCY);
                this.commit('user/settings/SET_AUTO_CONNECT', settings ? settings.autoConnect : false);
                this.commit('firms/SET_PRIMARY_FIRM', user.primaryFirm);
                delete user.primaryFirm;
            }
        },
        SET_EMAIL(state, email) {
            state.user.details.accountEmail = email;
        },
        SET_PHONE(state, phone) {
            state.user.details.accountPhone = phone;
        },
        SET_PROFILE_PICTURE(state, url) {
            state.user.details.profile_picture = url;
        },
        SET_HONORIFIC(state, honorific) {
            state.user.details.honorific = honorific;
        },
        SET_FIRST_NAME(state, firstName) {
            state.user.details.firstName = firstName;
            state.user.details.fullName = firstName + ' ' + state.user.details.lastName;
        },
        SET_LAST_NAME(state, lastName) {
            state.user.details.lastName = lastName;
            state.user.details.fullName = state.user.details.firstName + ' ' + lastName;
        },
        SET_2FA_ENABLED(state, value) {
            state.user.has2faEnabled = value;
        },
        SET_PASSWORD_UPDATED({ user }) {
            user.details.password_set = true;
        },
    },
    actions: {
        async submitEmailForm({ commit }, form) {
            await form.put('/api/executive/account/email', { clear: false });

            commit('SET_EMAIL', form.email);
        },
        async submitPhoneForm({ commit }, form) {
            await form.put('/api/executive/account/phone', { clear: false });

            commit('SET_PHONE', form.phone);
        },
        async checkPassword(state, form) {
            const { success } = await form.post('/api/executive/account/password');
            return success;
        },
        async submitResetPasswordForm({ commit }, form) {
            const { success } = await form.put('/api/executive/account/password');
            commit('SET_PASSWORD_UPDATED');
        },
        deleteAccount(state) {
            return nalRequest.delete('/api/executive/account');
        },
        async saveProfilePicture({ getters, commit }, form) {
            form.setUploadLimit(20);
            const url = await form.post(getters.apiUrl('profile-picture'));

            commit('SET_PROFILE_PICTURE', url);
            await this.dispatch('user/stats/refreshCompletion');
        },
        resendActivationEmail() {
            return nalRequest.post('/api/executive/account:resend');
        },
        async enable2FA({ commit }) {
            commit('SET_2FA_ENABLED', true);
            return await nalRequest.post('/api/2fa/enable');
        },
        async disable2FA({ commit }) {
            commit('SET_2FA_ENABLED', false);
            return await nalRequest.post('/api/2fa/disable');
        },
        async override2FA({ commit }) {
            commit('SET_2FA_ENABLED', false);
            return await nalRequest.post('/api/2fa/override');
        },
    },
};
