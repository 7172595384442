import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    links: null,
    phones: null,
    emails: null,
    addresses: null,
    linkedin: null,
};

export default {
    state,
    getters: {
        phones: state => state.phones,
        emails: state => state.emails,
        addresses: state => state.addresses,
        links: state => state.links && _.compact([
            state.linkedin && { title: 'linkedIn', url: state.linkedin },
            ...(state.links || []),
        ]),
        getContactForm: state => () => {
            return new Form(_.assignWith({}, {
                emails: [{
                    id: null,
                    address: '',
                    label: 'private',
                }],
                phones: [{
                    id: null,
                    number: '',
                    label: 'private',
                }],
                addresses: [{
                    id: null,
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    country: '',
                    postcode: '',
                    label: 'private',
                }],
                links: [{
                    id: null,
                    title: '',
                    url: '',
                }],
                linkedin: '',
            }, (no, def, key) => {
                let result = _.cloneDeep(_.get(state, key, def));
                if (_.isArray(result) && !result.length) {
                    result = def;
                }
                return result;
            }));
        },
    },
    mutations: {
        ...make.mutations(state),
    },
    actions: {
        async saveContact({ commit, rootGetters }, form) {
            form.formatData(data => {
                return {
                    ..._.omit(data, ['links', 'addresses', 'phones', 'emails']),
                    links: data.links.filter(link => link.url || link.title),
                    emails: _.filter(data.emails, 'address'),
                    phones: _.filter(data.phones, 'number'),
                    addresses: data.addresses.filter(address => {
                        return address.line1
                            || address.line2
                            || address.country
                            || address.city
                            || address.state
                            || address.postcode;
                    }),
                };
            });
            const contact = await form.post(rootGetters['user/apiUrl']('contact'), {
                scrollToFirstError: true,
            });

            commit('SET_EMAILS', contact.emails);
            commit('SET_ADDRESSES', contact.addresses);
            commit('SET_PHONES', contact.phones);
            commit('SET_LINKS', contact.links);
            commit('SET_LINKEDIN', contact.linkedin);
        },
    },
};
