import nalRequest from '@js/core/nalRequest.js';
import getStripe from '@js/core/stripe.js';
import { make } from 'vuex-pathify';

const state = {
    premium: null,
    subscription_ends_at: null,
    service: null,
    plan: null,
    history: [],
    next_payment_date: null,
    paypal_invoice_link: null,
    loaded: false,
    platinum: null,
    type: null,
    is_ee_subscription: false,
    status: null,
};

function getPaymentMethod(card, postCode, payment, setup = true) {
    let method = setup ? 'handleCardSetup' : 'handleCardPayment';
    return getStripe()[method](
        payment.client_secret,
        card,
        {
            payment_method_data: {
                billing_details: {
                    address: {
                        postal_code: postCode,
                    },
                },
            },
        }
    );
}

function getQueryString(request) {
    return _.chain(request).mapValues((value, key) => {
        if (value) {
            return `${key}=${value}`;
        }
    }).filter(item => !!item).join('&').value();
}

export default {
    namespaced: true,
    state,
    getters: {
        invoiceLink: (state) => (id) => {
            let invoice = _.find(state.history, { id });
            if (!invoice) {
                return null;
            }
            if (invoice.service === 'stripe') {
                return `/api/executive/subscription/invoice/${id}`;
            }
            return state.paypal_invoice_link;
        },
    },
    mutations: {
        ...make.mutations(state),
        SET_BILLING(state, billing) {
            this.commit('user/billing/SET_PREMIUM', billing && billing.is_subscribed && billing.type.includes('premium'));
            this.commit('user/billing/SET_PLATINUM', billing && billing.is_subscribed && billing.type.includes('platinum'));
            this.commit('user/billing/SET_PAYPAL_INVOICE_LINK', billing && billing.paypal_invoice_link);
            this.commit('user/billing/SET_HISTORY', (billing && billing.history) || []);
            this.commit('user/billing/SET_TYPE', billing && billing.is_subscribed && billing.type ? billing.type : 'free');
            if (billing && billing.is_subscribed) {
                this.commit('user/billing/SET_SUBSCRIPTION_ENDS_AT', billing.subscription_ends_at);
                this.commit('user/billing/SET_SERVICE', billing.service);
                this.commit('user/billing/SET_PLAN', billing.plan);
                this.commit('user/billing/SET_NEXT_PAYMENT_DATE', billing.next_payment_date);
                this.commit('user/billing/SET_IS_EE_SUBSCRIPTION', billing && billing.is_EE_subscription);
                this.commit('user/billing/SET_STATUS', billing && billing.status);
            }
        },
    },
    actions: {
        async upgrade(store, payload) {
            let request = _.pick(payload, ['currency', 'frequency', 'coupon', 'name', 'destination', 'proration_date']);
            if (payload.service === 'stripe') {
                const payment = await store.dispatch('fetchPayment');
                const { setupIntent, error } = await getPaymentMethod(payload.card, payload.postCode, payment);
                if (error) {
                    notify(error.message, 'alert');
                    throw error;
                }
                request.paymentMethod = setupIntent.payment_method;
            }

            let response;

            try {
                response = await nalRequest.post(`api/executive/subscription/${payload.service}`, request);
                if (response.redirect) {
                    location.href = response.redirect;
                    return;
                }
            } catch (error) {
                notify(error.responseJSON.error, 'alert');
                throw error;
            }

            // Only catch JS errors, not request issues which are already handled
            await store.dispatch('fetchBilling', true).catch(e => {
                if (e instanceof Error) {
                    throw e;
                }
            });
            this.commit('content/SET_STATUS', {});
            this.dispatch('content/fetchContent', true).catch(e => {
                if (e instanceof Error) {
                    throw e;
                }
            });

            return response;
        },
        async downgrade({ dispatch, commit, state }, { reason, force = false }) {
            let queryString = force ? '?force' : '';

            await nalRequest.delete(`api/executive/subscription/${state.service}` + `${queryString}`, { reason });

            dispatch('fetchBilling', true);
        },
        async updateCard({ state, dispatch }, payload) {
            const payment = await dispatch('fetchPayment');
            let { error, setupIntent } = await getPaymentMethod(payload.card, payload.postCode, payment);
            if (error) {
                notify(error.message, 'alert');
                return Promise.reject(error);
            }
            const response = nalRequest.put(
                `api/executive/${state.service}`,
                { paymentMethod: setupIntent.payment_method }
            );
            if (response.error) {
                error = response.error;
            } else {
                return response;
            }
        },
        async authorisePayment({ state }, payload) {
            const { error } = await getPaymentMethod(payload.card, payload.postCode, payload.payment, false);

            if (error) {
                notify(error.message, 'alert');
                return false;
            }

            notify('Authorisation successful');
            // location.reload();

            return true;
        },
        async fetchBilling({ commit, state }, force = false) {
            if (!state.loaded || force) {
                const { data } = await nalRequest.get(`api/executive/subscription/billing`);

                commit('SET_BILLING', data);
                commit('SET_LOADED', true);
            }
        },
        fetchPayment({ state }, payment = '') {
            return nalRequest.get(`api/executive/stripe/${payment}`);
        },
        async purchaseServices(store, payload) {
            let request = _.pick(payload, ['currency', 'firstname', 'lastname', 'email', 'coupon', 'items', 'destination']);

            const payment = await store.dispatch('fetchPayment');
            let { error, setupIntent } = await getPaymentMethod(payload.card, payload.postCode, payment);
            if (error) {
                notify(error.message, 'alert');
                throw error;
            }
            request.paymentMethod = setupIntent.payment_method;

            let response;

            try {
                response = await nalRequest.post(`api/executive/services/${payload.service}`, request);
                if (response.redirect) {
                    location.href = response.redirect;
                    return ;
                }
            } catch (error) {
                let responseJSON = error.responseJSON;
                if (error.status === 422 && responseJSON.errors) {
                    throw error;
                } else {
                    notify(responseJSON.error, 'alert');
                }
                throw error;
            }

            // this is when in the executive account
            // store.dispatch('fetchBilling', true).catch(e => {
            //     if (e instanceof Error) {
            //         throw e
            //     }
            // });

            return response;
        },
        async fetchProration(store, payload) {
            let request = _.pick(payload, ['currency', 'frequency', 'coupon', 'name']);
            let query = await getQueryString(request);

            try {
                return await nalRequest.get(`api/executive/subscription/stripe/swap?${query}`);
            } catch (error) {
                let responseJSON = error.responseJSON;
                notify(responseJSON.error, 'alert');
                throw error;
            }
        },
    },
};
