import { make } from 'vuex-pathify';

const state = {
    aspirations: null,
};

export default {
    state,
    getters: {
        getAspirationsForm: state => () => {
            return new Form(_.assignWith({}, {
                summary: '',
                permanentPosition: null,
                nedPosition: null,
                interimPosition: null,
                companyTypes: [],
                industries: [],
                locations: [],
                careers: [],
            }, (no, def, key) => {
                key = key.replace('Position', '');
                let result = _.get(state.aspirations, key, def);
                if (_.isPlainObject(result)) {
                    result = _.cloneDeep(result);
                    if (key === 'ned' && result.classifications) {
                        result.classifications = _.map(result.classifications, 'id');
                    }
                }
                if (_.isArray(result)) {
                    result = _.map(result, 'id');
                }
                return result;
            }));
        },
    },
    mutations: {
        ...make.mutations(state),
    },
    actions: {
        async saveAspirations({ commit, rootGetters }, form) {
            const aspirations = await form.post(rootGetters['user/apiUrl']('aspirations'), {
                scrollToFirstError: true,
            });

            commit('SET_ASPIRATIONS', aspirations.data);
            await this.dispatch('user/stats/refreshCompletion');
        },
    },
}
