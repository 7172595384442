import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    firms: null,
    loaded: false,
};

export default {
    namespaced: true,
    state,
    getters: {
    },
    mutations: {
        ...make.mutations(state),
        'SET_PRIVACY': (state, { firm, privacy }) => {
            firm.privacy = privacy;
        },
    },
    actions: {
        async fetchConnections({ commit, state }) {
            if (state.loaded) {
                return;
            }
            const { data } = await nalRequest.get(`/api/executive/connections`);
            if (data && data.length) {
                commit('SET_FIRMS', [...(state.firms || []), ...data]);
                this.commit('firms/CONNECT_TO_FIRMS', data);
            }
            commit('SET_LOADED', true);
        },
        updatePrivacy({ commit, state }, { firm, privacy }) {
            commit('SET_PRIVACY', { firm, privacy });
            nalRequest.put(`/api/executive/connections/${firm.id}`, {
                privacy,
            });
        },
        async connect({ commit, state }, { firm, code }) {
            let url = `/api/executive/connections/${firm.id}`;

            if (code) {
                url += `?invite=${code}`;
            }

            try {
                const { data } = await nalRequest.post(url);
                if (state.firms) {
                    commit('SET_FIRMS', [...state.firms || [], data]);
                }

                this.commit('firms/CONNECT_TO_FIRMS', [data]);
                this.commit('user/stats/INCREMENT_CONNECTIONS');
            } catch (e) {
                if (+e.status === 422) {
                    return notify('You cannot connect to that firm!', 'alert');
                }
                if (!e.status) {
                    throw e;
                }
            }
        },
        acceptInvite({ dispatch }, firm) {
            return dispatch('connect', { firm, code: firm.invited });
        },
        async dismiss(context, firm) {
            await nalRequest.put(`/api/executive/connections/dismiss/${firm.id}`);

            this.commit('firms/DISMISS_FIRM', firm);
        },

        async disconnect({ commit, state }, firm) {
            await nalRequest.delete(`/api/executive/connections/${firm.id}`);
            let index = _.findIndex(state.firms, ['id', firm.id]);

            if (state.firms) {
                commit('SET_FIRMS', [...state.firms.slice(0, index), ...state.firms.slice(index + 1)]);
            }

            this.commit('firms/DISCONNECT_FROM_FIRMS', [firm]);
            this.commit('user/stats/DECREMENT_CONNECTIONS');
        },
    },
};
