<template>
    <div class="o-account-payment-confirmation">
        <account-action
            header="Authorise your payment"
            :loading="loading"
            :buttons="updateButton"
            @action="updateCard"
        >
            <div
                v-if="!loading && !succeeded"
                class="o-account-payment-confirmation__sub"
            >
                <div v-if="confirmation === 'purchase'">
                    Your payment did not go through. Please enter your card details again.
                </div>
                <div v-else>
                    This card will <span class="u-bold">not</span> be charged until your next billing date.
                </div>
            </div>
            <account-stripe
                v-if="!succeeded"
                v-model="postcode"
                class="o-account-payment-confirmation__stripe"
                @ready="card = $event"
            >
            </account-stripe>
            <div
                v-else
                class="o-account-payment-confirmation__success"
            >
                <div v-if="confirmation === 'purchase'">
                    Your payment has been successfully authorised.
                </div>
                <div v-else>
                    Your subscription has been successfully authorised.
                </div>
            </div>
        </account-action>
    </div>
</template>

<script>
import { get, call } from 'vuex-pathify';
import AccountStripe from './account-stripe.vue';
import AccountAction from './account-action.vue';

export default {
    name: 'AccountPaymentConfirmation',
    components: {
        AccountStripe,
        AccountAction,
    },
    emits: ['close'],
    props: {
        paymentId: String,
        confirmation: String,
    },
    data() {
        return {
            postcode: '',
            card: null,
            loading: false,
            payment: null,
        };
    },
    computed: {
        succeeded() {
            return this.payment && this.payment.status === 'succeeded';
        },
        updateButton() {
            return this.loading || this.succeeded
                ? {}
                : {
                    action: 'Authorise payment',
                };
        },
        confirmationRoute() {
            return this.confirmation + '.confirmation';
        },
        user() {
            return window.nal.user || window.nal.store.state.user;
        },
        billing: get('user/billing', false),
    },
    methods: {
        async updateCard() {
            this.loading = true;
            try {
                let success = await this.$store.dispatch(
                    'user/billing/authorisePayment',
                    { card: this.card, postCode: this.postcode, payment: this.payment }
                );
                if (success) {
                    if (this.user) {
                        await this.fetchBilling(true);
                    }
                    if (this.confirmation !== 'modal') {
                        await this.$router.replace({ name: this.confirmationRoute });
                    } else {
                        await this.$router.replace({ name: this.$route.name, query: { modal: 'account-welcome' } });
                    }
                    this.$emit('close');
                }
            } finally {
                this.loading = false;
            }
        },
        ...call('user/billing', ['fetchBilling']),
    },
    async created() {
        this.loading = true;
        const { payment } = await this.$store.dispatch('user/billing/fetchPayment', this.paymentId);
        this.payment = payment;
        this.loading = false;
    },
};
</script>

<style lang="scss" scoped>
    @import "@sass/variables";

    .o-account-payment-confirmation {
        height: 100%;

        &__sub {
            margin-bottom: 20px;
        }

        &__success {
            background: $color__success;
            padding: 15px;
        }
    }
</style>
