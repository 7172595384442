import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    completion: {
        score: null,
        messages: null,
    },
    connections: null,
    search: {
        count: null,
        connectedCount: null,
        parameters: null,
    },
    recommendedFirms: [],
    averageSalary: null,
    loaded: false,
};

export default {
    namespaced: true,
    state,
    getters: {
        connectedSearchCount(state) {
            return state.search.connectedCount;
        },
        nonConnectedSearchCount(state) {
            return state.search.count - state.search.connectedCount;
        },
        searchParameters(state) {
            return state.search.parameters;
        },
    },
    mutations: {
        ...make.mutations(state),
        'INCREMENT_CONNECTIONS'(state, step = 1) {
            state.connections += step;
        },
        'DECREMENT_CONNECTIONS'(state, step = 1) {
            state.connections -= step;
        },
    },
    actions: {
        async fetchStats({ commit, state }) {
            if (state.loaded) {
                return;
            }
            const { data: stats } = await nalRequest.get('/api/executive/stats');

            if (!stats) {
                return;
            }

            commit('SET_COMPLETION', stats.completion);
            commit('SET_CONNECTIONS', stats.connections);
            commit('SET_SEARCH', stats.search);
            commit('SET_RECOMMENDED_FIRMS', stats.recommendedFirms);
            commit('SET_AVERAGE_SALARY', stats.averageSalary);
            commit('SET_LOADED', true);
        },
        async refreshCompletion({ commit }) {
            const { data: completion } = await nalRequest.get('/api/executive/completion');
            commit('SET_COMPLETION', completion);
        },
    },
};
