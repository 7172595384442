import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    partners: [],
    tags: [],
    loaded: false,
};

export default {
    namespaced: true,
    state,
    getters: {
        forType: (state) => (type) => {
            return _.filter(state.partners, { type });
        },
        tagsOfType: (state) => (type) => {
            return _.filter(state.tags, { type });
        },
        partner: (state) => (partner) => {
            let friendly_url = _.isObject(partner) ? partner.friendly_url : partner;
            return _.find(state.partners, { friendly_url });
        },
        tag: (state) => (id) => {
            return _.find(state.tags, { id });
        },
    },
    mutations: {
        ...make.mutations(state),
        'SET_FAVOURITE'(state, { partner, favourite }) {
            window.setVueObject(partner, 'favourite', favourite);
        },
    },
    actions: {
        async fetchPartner(store, id) {
            return await store.dispatch('fetchPartners');
        },
        async fetchPartners({ commit, state }) {
            if (state.loaded) {
                return;
            }
            const response = await nalRequest.get('/api/executive/partners');
            if (!response.data || !response.meta) {
                return;
            }
            const { data: partners, meta: { tags } } = response;
            commit('SET_PARTNERS', partners);
            commit('SET_TAGS', tags);
            commit('SET_LOADED', true);
        },
        toggleFavourite({ commit, state }, partner) {
            let method = partner.favourite ? 'delete' : 'post';

            nalRequest[method](`/api/executive/partners/${partner.id}/favourite`);
            commit('SET_FAVOURITE', { partner, favourite: !partner.favourite });
        },
    },
};
