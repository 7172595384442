import hiddenContexts from "../../articles/hiddenContexts";

export default function getAvailableFilters(content, key) {
    let isContexts = key === 'contexts';
    let query = _(content).flatMap(key);
    query = isContexts ? query.uniq() : query.uniqBy('id');

    if (isContexts) {
        query = query.map(context => {
            return {
                id: context,
                name: trans(`articles.labels.${context}`),
            };
        }).filter(context => {
            return !hiddenContexts.includes(context.id);
        });
    }

    return query.orderBy('name', 'asc').value();
}