import checkFilter from './filterRx.js';

export function stringDoesntMatch(match, str) {
    return match && !checkFilter(match, str);
}

export function stringsDontMatch(match, strs) {
    return match && !strs.some(str => checkFilter(match, str));
}

export function arraysDontMatch(matches, arr) {
    return matches && matches.length && arr && !matches.every(match => arr.includes(match));
}

export function idsDontMatch(matches, arr) {
    return matches && matches.length && !matches.every(match => _.some(arr, ['id', match.id]));
}

export function locationsDontMatch(matches, locations) {
    return matches && matches.length && !matches.every(match => {
        return locations.some(location => {
            do {
                if (location.id === match.id) {
                    return true;
                }
            } while ((location = location.parent));
            return false;
        });
    });
}
