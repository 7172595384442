import { DEFAULT_CURRENCY } from '@js/core/currencies.js';
import { positions } from '@js/core/positionTypes.js';

import { make } from 'vuex-pathify';

const state = {
    confidential: null,
};

const positionsForm = {};

for (const positionKey in positions) {
    const position = positions[positionKey];
    positionsForm[position.formInfo.confidential.positionKey] = {
        amount: position.default,
        currency: DEFAULT_CURRENCY,
    };
}

export default {
    state,
    getters: {
        getConfidentialForm: state => () => {
            return new Form(_.assignWith({}, {
                additionalInfo: '',
                bonus: null,
                probono: false,
                notice: null,
                ...positionsForm,
            }, (no, def, key) => {
                return _.clone(_.get(state.confidential, key, def));
            }));
        },
    },
    mutations: {
        ...make.mutations(state),
    },
    actions: {
        async saveConfidential({ commit, rootGetters }, form) {
            const confidential = await form.post(rootGetters['user/apiUrl']('confidential'), { clear: false });

            commit('SET_CONFIDENTIAL', confidential.data);
            await this.dispatch('user/stats/refreshCompletion');
        },
    },
};
