import nalRequest from '@js/core/nalRequest.js';
import contextModules from './contextModules.js';
import { make } from 'vuex-pathify';
import { arraysDontMatch, idsDontMatch, stringDoesntMatch } from '../../../core/filterUtils.js';
import getAvailableFilters from './getAvailableFilters.js';

function constructDateTime() {
    let date = new Date;
    return [date.getUTCFullYear(), date.getUTCMonth(), date.getDate()].join('-') + ' '
        + [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()].join(':');
}

const state = {
    allFaqs: [],
    allArticles: [],
    allWebinars: [],
    hasNewArticles: false,
    hasNewWebinars: false,
    hasNewFaqs: false,
    tags: [],
    status: {},
    now: constructDateTime(),
};

function getContentKey(type) {
    let key = 'all' + type[0].toUpperCase() + type.substr(1);
    if (!_.endsWith(type, 's')) {
        key += 's';
    }
    return key;
}
function getNewKey(type) {
    if (!_.endsWith(type, 's')) {
        type += 's';
    }
    return 'hasNew' + type[0].toUpperCase() + type.substr(1);
}

export default {
    namespaced: true,
    modules: {
        ...contextModules,
    },
    state,
    mutations: {
        ...make.mutations(state),
        'SET_ARTICLES'(state, articles) {
            if (!articles.length) {
                return;
            }
            let type = articles[0].type;
            let articleKey = getContentKey(type);
            let newCountKey = getNewKey(type);
            let existingArticles = state[articleKey];
            // If some articles have already been loaded we need to check and
            // make sure we don't replace them.
            state[articleKey] = articles.map(article => {
                return _.find(existingArticles, ['friendly_url', article.friendly_url]) || article;
            });

            state[newCountKey] = _.some(state[articleKey], 'is_new');
        },
        'REFRESH_NOW'(state) {
            state.now = constructDateTime();
        },
        'SET_CONTENT'(state, article) {
            let type = article.type;
            article.loaded = true;
            let articleKey = getContentKey(type);
            let index = _.findIndex(state[articleKey], ['id', article.id]);
            if (~index) {
                state[articleKey].splice(index, 1, article);
            } else {
                state[articleKey].push(article);
            }
        },
    },
    getters: {
        availableFilters: state => (key, types) => {
            let content = _.flatMap(types, type => state[getContentKey(type)]);
            return getAvailableFilters(content, key);
        },
        getStatus: state => (context = 'all') => {
            return state.status[context];
        },
        getArticle: state => friendly_url => {
            let key = 'friendly_url';
            friendly_url = friendly_url.toLowerCase();
            if (+friendly_url) {
                key = 'id';
                friendly_url = +friendly_url;
            }
            return _.find(state.allArticles, [key, friendly_url])
                || _.find(state.allWebinars, [key, friendly_url]);
        },
        contextStatus: state => context => state.status[context],
        filteredFaqs: (state, getters) => filters => {
            return getters.filteredContent('faqs', filters);
        },
        filteredWebinars: (state, getters) => filters => {
            return getters.filteredContent('webinars', filters);
        },
        filteredArticles: (state, getters) => filters => {
            return getters.filteredContent('articles', filters);
        },
        filteredArticlesAndWebinars: (state, getters) => filters => {
            return getters.filteredContent('orderedContent', filters);
        },
        filteredContent: (state, getters) => (type, filters) => {
            let content;
            let articleKey = getContentKey(type);
            if (filters.topics && filters.topics.length === 1) {
                content = getters[`${filters.topics[0]}/${type}`];
            } else if (filters.topics && filters.topics.length) {
                content = state[articleKey].filter(article => !arraysDontMatch(filters.topics, article.contexts));
            } else {
                content = state[articleKey];
            }
            return content.filter(article => {
                return !(
                    (stringDoesntMatch(filters.title, article.title)
                    && stringDoesntMatch(filters.title, article.author))
                    || idsDontMatch(filters.industries, article.industries)
                    || idsDontMatch(filters.careers, article.careers)
                    || idsDontMatch(filters.locations, article.locations)
                    || arraysDontMatch(_.map(filters.tags, 'text'), _.map(article.tags, 'text'))
                );
            });
        },
    },
    actions: {
        async fetchContent({ commit, state }, force = false) {
            if (force) {
                commit('SET_STATUS', {});
                commit('SET_ALL_FAQS', []);
                commit('SET_ALL_ARTICLES', []);
                commit('SET_ALL_WEBINARS', []);
            }
            if (state.status.all) {
                return;
            }

            commit('SET_STATUS', {
                ..._.omit(state.status, ('all')),
                all: 'loading',
            });

            let url = '/api/articles?all=1';
            const { data: { faqs, articles, webinars }, meta: { tags } } = await nalRequest.get(url);
            commit('SET_ARTICLES', faqs);
            commit('SET_ARTICLES', articles);
            commit('SET_ARTICLES', webinars);
            commit('SET_TAGS', tags);
            commit('SET_STATUS', {
                ..._.omit(state.status, ('all')),
                all: 'loaded',
            });
        },
        async fetchArticle({ commit, getters }, url) {
            let article = getters.getArticle(url);
            if (article && article.loaded) {
                return;
            }

            const { data } = await nalRequest.get(`/api/articles/${url}`);
            commit('SET_CONTENT', data);
        },
    },
};
