import {
    stringDoesntMatch,
    stringsDontMatch,
    arraysDontMatch,
    idsDontMatch,
    locationsDontMatch,
} from '@js/core/filterUtils.js';
import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    firms: null,
    loaded: false,
    primaryFirm: null,
    hasAvailableConnections: null,
};

export default {
    namespaced: true,
    state,
    mutations: {
        ...make.mutations(state),
        'CONNECT_TO_FIRMS'(state, firms) {
            _.intersectionBy(state.firms, firms, 'id').forEach(firm => {
                firm.connected = true;
                firm.invited = false;
            });
        },
        'DISCONNECT_FROM_FIRMS'(state, firms) {
            _.intersectionBy(state.firms, firms, 'id').forEach(firm => firm.connected = false);
        },
        'DISMISS_FIRM'(state, firm) {
            firm.invited = false;
        },
    },
    getters: {
        firm: (state) => (firm) => {
            let id = _.isInteger(firm) ? firm : firm.id;
            return _.find(state.firms, { id });
        },
        filteredFirms: (state, getters) => filters => {
            if (!state.firms) {
                return [];
            }
            let tags = filters.tags;
            let firms;
            if (tags.length === 1) {
                firms = getters[`${tags[0]}Firms`];
            } else {
                firms = tags.length ? state.firms.filter(firm => !tags.some(tag => !firm[tag])) : state.firms;
            }
            firms = firms.filter(firm => {
                return !(
                    (stringDoesntMatch(filters.name, firm.name)
                    && stringsDontMatch(filters.name, _.map(firm.team, 'name')))
                    || idsDontMatch(filters.industries, firm.industries)
                    || idsDontMatch(filters.functions, firm.functions)
                    || arraysDontMatch(filters.positionTypes, firm.positionTypes)
                    || locationsDontMatch(filters.locations, firm.locations)
                );
            });
            if (filters.sort) {
                firms = _.sortBy(firms, 'name');
            }

            return firms;
        },
        hasFreeFirms: (state, getters) => {
            return !!getters.freeFirms.length;
        },
        freeFirms: state => {
            return _.filter(state.firms, 'free');
        },
        recommendedFirms: state => {
            return _.filter(state.firms, 'recommended');
        },
        connectedFirms: state => {
            return _.filter(state.firms, 'connected');
        },
        invitedFirms: state => {
            return _.filter(state.firms, 'invited');
        },
        hasInvites: (state, getters) => {
            return !!getters.invitedFirms.length;
        },
        availableConnections: (state, getters, rootState, rootGetters) => {
            if (rootGetters['user/isUpgraded']) {
                return Infinity;
            }
            if (state.loaded) {
                return (8 - _.filter(getters.connectedFirms, ['free', false]).length) > 0;
            }
            return state.hasAvailableConnections !== null ? state.hasAvailableConnections : true;
        },
    },
    actions: {
        async fetchFirm({ state, commit }, id) {
            try {
                const response = await nalRequest.get(`/api/executive/firms/${id}`);

                if (response.redirect) {
                    return;
                }

                const { data: firm, meta: { hasAvailableConnections } } = response;

                if (state.loaded) {
                    let index = _.findIndex(state.firms, { id });
                    let firms;
                    if (~index) {
                        firms = [...state.firms.slice(0, index), firm, ...state.firms.slice(index + 1)];
                    } else {
                        firms = [...state.firms, firm];
                    }
                    commit('SET_FIRMS', firms);
                } else {
                    commit('SET_FIRMS', [firm]);
                    commit('SET_HAS_AVAILABLE_CONNECTIONS', hasAvailableConnections);
                }
            } catch (e) {
                if (+e.status !== 404) {
                    throw e;
                }
            }
        },
        async fetchFirms({ state, commit }) {
            if (state.loaded) {
                return;
            }
            const response = await nalRequest.get('/api/executive/firms');

            if (response && response.data) {
                const firms = response.data;

                commit('SET_FIRMS', firms);
                this.commit('user/stats/SET_CONNECTIONS', _.filter(firms, 'connected').length);
                commit('SET_LOADED', true);
            }
        },
    },
};
