import { stringDoesntMatch, idsDontMatch, locationsDontMatch, arraysDontMatch } from '@js/core/filterUtils.js';

function languagesDontMatch(matches, languages) {
    if (!languages || !languages.length) {
        languages = ['en'];
    }
    return arraysDontMatch(_.map(matches, 'code'), languages);
}

const partnerTypes = ['coaching', 'services'];
let modules = {};

partnerTypes.forEach(function (type) {
    modules[type] = {
        namespaced: true,
        getters: {
            partners(state, getters, rootState, rootGetters) {
                return rootGetters['partners/forType'](type);
            },
            tags(state, getters, rootState, rootGetters) {
                return rootGetters['partners/tagsOfType'](type);
            },
            availableLanguages(state, getters) {
                return _(getters.partners).flatMap('languages').push({ code: 'en', name: trans('common.english') }).uniqBy('code').value();
            },
            filteredPartners: (state, getters) => (filters) => {
                return getters.partners.filter(partner => {
                    return !((stringDoesntMatch(filters.name, partner.name)
                        && stringDoesntMatch(filters.name, partner.tagline))
                        || idsDontMatch(filters.categories, partner.tags)
                        || languagesDontMatch(filters.languages, _.map(partner.languages, 'code'))
                        || locationsDontMatch(filters.locations, partner.locations)
                        || (filters.favourite && !partner.favourite)
                        || (filters.discount && !partner.discount));
                });
            },
        },
    };
});

export default modules;
