import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    educations: null,
};

export default {
    state,
    getters: {
        getQualificationForm: state => qualification => {
            return new Form(_.assignWith({}, {
                id: null,
                primary: false,
                school: null,
                degree: null,
                field: null,
                startDate: null,
                endDate: null,
                summary: null,
                hide_dates: false,
            }, (no, def, key) => {
                let resourceKey = key;
                let value = (qualification && qualification[resourceKey]) || def;
                if (resourceKey === 'endDate' && value && !_.isNumber(value)) {
                    value = 9999;
                }
                return value;
            }));
        },
    },
    mutations: {
        ...make.mutations(state),
        'UPDATE_PRIMARY_EDUCATION'(state, primary) {
            state.educations.forEach(qualification => {
                if (qualification.id !== primary.id && qualification.primary) {
                    qualification.primary = false;
                }
                if (qualification.id === primary.id) {
                    qualification.primary = true;
                }
            });
        },
    },
    actions: {
        async saveQualification({ commit, state, rootGetters }, form) {
            let exists = !!form.id;
            let method = exists ? 'put' : 'post';
            const response = await form[method](rootGetters['user/apiUrl']('education', form.id));
            const qualification = response.data;

            let index = _.findIndex(state.educations, ['id', qualification.id]);
            let educations;

            if (~index) {
                educations = [...state.educations.slice(0, index), qualification, ...state.educations.slice(index + 1)];
            } else {
                educations = [...state.educations, qualification];
            }

            commit('SET_EDUCATIONS', educations);

            if (qualification.primary) {
                commit('UPDATE_PRIMARY_EDUCATION', qualification);
            }
            await this.dispatch('user/stats/refreshCompletion');
        },
        async removeQualification({ commit, state, rootGetters }, qualification) {
            let index = _.findIndex(state.educations, ['id', qualification.id]);
            if (~index) {
                commit('SET_EDUCATIONS', [...state.educations.slice(0, index), ...state.educations.slice(index + 1)]);
            }

            await nalRequest.delete(rootGetters['user/apiUrl']('education', qualification.id));
            await this.dispatch('user/stats/refreshCompletion');
        },
    },
};
