import getAvailableFilters from "./getAvailableFilters";

const articleContexts = [
    'account',
    'profile',
    'connect',
    'skills',
    'brand',
    'coaching',
    'transition',
    'services',
    'website-news',
    'website-faq',
    'articles',
    'webinars',
];
let modules = {};

articleContexts.forEach(function (type) {
    modules[type] = {
        namespaced: true,
        getters: {
            availableFilters: (state, getters) => (key, types) => {
                let content = _.flatMap(types, type => getters[type]);
                return getAvailableFilters(content, key);
            },
            articles(state, getters, rootState) {
                return rootState.content.allArticles.filter(article => article.contexts && article.contexts.includes(type));
            },
            faqs(state, getters, rootState) {
                return rootState.content.allFaqs.filter(faq => faq.contexts && faq.contexts.includes(type));
            },
            webinars(state, getters, rootState) {
                return rootState.content.allWebinars.filter(webinar => webinar.contexts && webinar.contexts.includes(type));
            },
            allContent(state, getters) {
                return getters.articles.concat(getters.webinars);
            },
            orderedContent(state, getters) {
                let oldestFeatured = moment().subtract(2, 'months');
                return _.orderBy(getters.allContent, [article => {
                    return article.featured && oldestFeatured.isBefore(article.made_public_at);
                }, article => {
                    return article.type === 'webinar' ? article.webinar_start_time : article.made_public_at;
                }], ['desc', 'desc']);
            },
            hasNew: (state, getters) => {
                return _.some(getters.allContent, 'is_new');
            },
        }
    }
});

export default modules;
