import AccountWelcome from './vue-components/account/account-welcome.vue';
import AccountPaymentConfirmation from './vue-components/account/account-payment-confirmation.vue';
import AccountOverlay from './vue-components/account/account-overlay.vue';

const modals = {
    'account-welcome': {
        data: {
            component: AccountWelcome,
            style: { height: '440px', width: '500px' },
            clearBacktrace: true,
        },
    },
    'confirm-payment'(route) {
        return {
            data: {
                component: AccountPaymentConfirmation,
                style: { height: '400px', width: '500px' },
                clearBacktrace: true,
                data: {
                    paymentId: route.query.payment,
                    confirmation: route.query.confirmation,
                },
            },
        };
    },
    'upgrade'() {
        return {
            data: {
                component: AccountOverlay,
                style: { height: '800px', width: '1000px' },
                innerStyle: { padding: 0 },
            },
        };
    },
};

export default function displayRouteModal(route) {
    let modalInfo = modals[route.query.modal];
    if (_.isFunction(modalInfo)) {
        modalInfo = modalInfo(route);
    }
    this.createModal(modalInfo);
}
