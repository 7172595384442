import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';
import educations from './profile/educations.js';
import positions from './profile/positions.js';
import confidential from './profile/confidential.js';
import aspirations from './profile/aspirations.js';
import contact from './profile/contact.js';

const state = {
    currentStatus: null,
    documents: null,
    languages: null,
    headline: null,
    import_count: null,
    loaded: false,
};

export default {
    namespaced: true,
    state,
    modules: {
        contact,
        educations,
        positions,
        confidential,
        aspirations,
    },
    getters: {
        educations: state => state.educations.educations,
        positions: state => state.positions.positions,
        confidential: state => state.confidential.confidential,
        aspirations: state => state.aspirations.aspirations,
        pdfDownloadLink: state => (showAspirations, showSalary) => {
            return `/api/executive/profile?format=pdf&aspirations=${+showAspirations}&salary=${+showSalary}`;
        },
        documentDownloadLink: (state, getters, rootState, rootGetters) => (doc) => {
            return rootGetters['user/apiUrl']('documents', doc.id);
        },
        getResumeForm: state => () => {
            return new Form({
                resume: null,
            }).setUploadLimit(20);
        },
        getDocumentForm: state => () => {
            return new Form({
                document: null,
            });
        },
        maxImportLimitReached: (state, getters, rootState, rootGetters) => {
            return state.import_count >= (rootGetters['user/isUpgraded'] ? 30 : 3);
        },
        getInfoForm: (state, getters, rootState) => () => {
            return new Form({
                honorific: rootState.user.user.details.honorific,
                firstname: rootState.user.user.details.firstName,
                lastname: rootState.user.user.details.lastName,
                headline: state.headline || '',
                status: !_.isNil(state.currentStatus.status.value) ? state.currentStatus.status.value : 2,
                positionType: state.currentStatus.positionType || 'permanent',
                nedClassificationId: state.currentStatus.classification?.id || 1,
                careers: state.currentStatus.careers.map(career => career.id),
                industries: state.currentStatus.industries.map(industry => industry.id),
                locations: state.currentStatus.locations.map(location => location.id),
            });
        },
    },
    mutations: {
        ...make.mutations(state),
        'SET_PROFILE'(state, profile) {
            [
                'educations',
                'aspirations',
                'positions',
                'currentStatus',
                'confidential',
                'documents',
                'languages',
                'headline',
                'links',
                'linkedin',
                'phones',
                'emails',
                'addresses',
                'import_count',
            ].forEach(field => {
                const method = field === 'currentStatus' ? 'SET_CURRENT_STATUS' : 'SET_' + field.toUpperCase();
                this.commit('user/profile/' + method, profile[field]);
            });
        },
    },
    actions: {
        async fetchProfile({ state, commit }) {
            if (state.loaded) {
                return;
            }
            const { data } = await nalRequest.get('/api/executive/profile');

            if (!data) {
                return;
            }
            commit('SET_PROFILE', data);
            commit('SET_LOADED', true);
        },
        async uploadCv({ commit, state }, form) {
            try {
                const { data: status, meta } = await form.put('/api/executive/profile');
                commit('SET_PROFILE', status);
                this.commit('user/SET_HONORIFIC', meta.honorific);
            } catch (error) {
                if (error.status === 403) {
                    const message = _.get(error, 'responseJSON.message') || trans('common.alert.403');
                    notify(message, 'alert');
                }

                if (form.errors.has('resume')) {
                    notify(form.errors.get('resume'), 'alert');
                }
                return Promise.reject(error);
            }
        },
        async addLanguage({ commit, state, rootGetters }, { fluency, language }) {
            const { data } = await nalRequest.post(rootGetters['user/apiUrl']('languages'), {
                fluency,
                code: language.id,
            });
            commit('SET_LANGUAGES', [...state.languages, data]);
            await this.dispatch('user/stats/refreshCompletion');
        },
        async removeLanguage({ commit, state, rootGetters }, language) {
            commit('SET_LANGUAGES', state.languages.filter(lang => language !== lang));
            await nalRequest.delete(rootGetters['user/apiUrl']('languages', language.id));
            await this.dispatch('user/stats/refreshCompletion');
        },
        async uploadDocument({ commit, state, rootGetters }, form) {
            const doc = await form.post(rootGetters['user/apiUrl']('documents'));
            commit('SET_DOCUMENTS', [...state.documents, doc]);
        },
        async removeDocument({ commit, state, rootGetters }, removedDoc) {
            commit('SET_DOCUMENTS', state.documents.filter(doc => doc !== removedDoc));
            return await nalRequest.delete(rootGetters['user/apiUrl']('documents', removedDoc.id));
        },
        async saveBasic({ commit, state, rootGetters }, form) {
            const { data: status, meta } = await form.post(rootGetters['user/apiUrl']('current-status'), {
                scrollToFirstError: true,
            });
            commit('SET_CURRENT_STATUS', status);
            commit('SET_HEADLINE', meta.headline);
            this.commit('user/SET_FIRST_NAME', meta.firstName);
            this.commit('user/SET_LAST_NAME', meta.lastName);
            this.commit('user/SET_HONORIFIC', meta.honorific);
            await this.dispatch('user/stats/refreshCompletion');
        },
    },
};
