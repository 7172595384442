import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    currency: null,
    inviteNotifications: null,
    updateNotifications: null,
    useAddressOnInvoice: null,
    address: null,
    autoConnect: null,
    loaded: false,
};

export default {
    namespaced: true,
    state,
    getters: {
        getAddressForm(state) {
            let address = state.address || {};

            return new Form({
                line1: address.line1 || '',
                line2: address.line2 || '',
                city: address.city || '',
                state: address.state || '',
                country: address.country || '',
                postcode: address.postcode || '',
            });
        },
    },
    mutations: {
        ...make.mutations(state),
    },
    actions: {
        async fetchSettings({ commit, state }) {
            if (!state.loaded) {
                const { data: settings } = await nalRequest.get('/api/executive/settings');
                if (!settings) {
                    commit('SET_LOADED', true);
                    return;
                }
                commit('SET_CURRENCY', settings.currency);
                commit('SET_INVITE_NOTIFICATIONS', settings.inviteNotifications);
                commit('SET_UPDATE_NOTIFICATIONS', settings.updateNotifications);
                commit('SET_USE_ADDRESS_ON_INVOICE', settings.useAddressOnInvoice);
                commit('SET_ADDRESS', settings.address);
                commit('SET_AUTO_CONNECT', settings.autoConnect);
                commit('SET_LOADED', true);
            }
        },
        saveInviteNotifications({ state }) {
            nalRequest.put('/api/executive/settings', {
                inviteNotifications: state.inviteNotifications,
                updateNotifications: state.updateNotifications,
            });
        },
        saveUpdateNotifications() {
            nalRequest.put('/api/executive/settings', {
                inviteNotifications: state.inviteNotifications,
                updateNotifications: state.updateNotifications,
            });
        },
        saveCurrency() {
            nalRequest.put('/api/executive/account/currency', {
                currency: state.currency,
            });
        },
        async saveAddress({ state, commit }, form) {
            const emptyForm = !_(form.data()).values().map(v => v.trim()).compact().value().length;

            let url = '/api/executive/address/';
            let method = 'post';

            if (state.address && state.address.id && emptyForm) {
                method = emptyForm ? 'delete' : 'put';
            } else if (emptyForm) {
                return;
            }

            form.append('label', 'invoice');

            const address = await form[method](url);

            commit('SET_ADDRESS', method === 'delete' ? null : address);
        },
        async saveAutoConnect({ state }, force = false) {
            let query = force ? '?force' : '';
            let url = '/api/executive/connections/autoconnect' + query;
            const { data: connectedFirms } = await nalRequest.put(url, {
                autoConnect: state.autoConnect,
            });
            if (connectedFirms) {
                if (this.get('user/connections/firms')) {
                    this.commit('user/connections/SET_FIRMS', [...connectedFirms, ...this.get('user/connections/firms')]);
                }
                this.commit('user/stats/INCREMENT_CONNECTIONS', connectedFirms.length);
                this.commit('firms/CONNECT_TO_FIRMS', connectedFirms);
            }
        },
    },
};
