function filterRx(query) {
    return query.replace(/[-[\]/{}()*+?.\\^$]/g, "\\$&").replace(/ OR /g, '|').replace(/ AND /g, '&&').replace(/\s+/g, '\\s*');
}

function filterFn(regEx, value) {
    return regEx.split('&&').map(rx => new RegExp(rx, 'i')).some(rx => rx.test(value));
}

export default function checkFilter(query, value) {
    return filterFn(filterRx(query), value);
}
