import nalRequest from '@js/core/nalRequest.js';
import { make } from 'vuex-pathify';

const state = {
    notifications: [],
    unreadTotal: null,
    total: null,
    visible: false,
    loaded: false,
};

export default {
    namespaced: true,
    state,
    mutations: {
        ...make.mutations(state),
        SET_NOTIFICATIONS_READ(state) {
            state.notifications.forEach(notification => {
                !notification.read && state.unreadTotal--;
                notification.read = true;
            });
        },
        DELETE_NOTIFICATION(state, notification) {
            let index = state.notifications.indexOf(notification);
            if (~index) {
                window.deleteIndexFromVueArray(state.notifications, index);
            }
            if (!notification.read) {
                state.unreadTotal--;
            }
            state.total--;
        },
    },
    actions: {
        async fetchNotifications({ commit, loaded }) {
            if (!state.loaded) {
                const response = await nalRequest.get('/api/notifications');
                if (response.data) {
                    const notifications = response.data;
                    const total = response.meta.total;
                    const unreadTotal = response.meta.unreadTotal;
                    commit('SET_NOTIFICATIONS', notifications);
                    commit('SET_TOTAL', total);
                    commit('SET_UNREAD_TOTAL', unreadTotal);
                    commit('SET_LOADED', true);
                }
            }
        },
        readAllNotifications({ commit, state }) {
            nalRequest.post('/api/notifications', { notifications: _.map(state.notifications, 'id') });
            commit('SET_NOTIFICATIONS_READ');
        },
        deleteNotification({ commit }, notification) {
            nalRequest.delete(`/api/notifications/${notification.id}`);
            commit('DELETE_NOTIFICATION', notification);
        },
    },
};
