<template>
    <div class="o-account-welcome">
        <div class="o-account-welcome__success">
            <i class="fal fa-check-circle o-account-welcome__icon u-primary-color__text"></i>
            <p class="o-account-welcome__payment">
                {{ trans('account.confirmation.success') }}
            </p>
        </div>
        <div class="o-account-welcome__welcome">
            {{ trans('account.confirmation.welcome.' + planType) }}
        </div>

        <slot></slot>

        <div class="o-account-welcome__connect u-primary-color__bg--light">
            <radio-holder
                v-model="autoConnect"
                type="checkbox"
                radioBtnClass="c-radio-btn--lg"
                labelClass="c-radio-holder__label--lowercase"
                :labelStyle="{ 'font-size': '16px', 'line-height': '24px', 'margin-left': '20px' }"
                @update:modelValue="saveAutoConnect"
            >
                {{ trans('account.confirmation.connect') }}
            </radio-holder>
        </div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import knowsUpgrade from '@js/executives/vue-mixins/knowsUpgrade.js';

export default {
    name: 'AccountWelcome',
    mixins: [
        knowsUpgrade,
    ],
    props: {

    },
    data() {
        return {
        };
    },
    computed: {
        autoConnect: sync('user/settings/autoConnect'),
    },
    methods: {
        async saveAutoConnect() {
            await this.$store.dispatch('user/settings/saveAutoConnect');
        },
    },
};
</script>

<style lang="scss" scoped>
    .o-account-welcome {
        padding-top: 30px;

        &__success {
            margin-bottom: 30px;
            text-align: center;
        }

        &__icon {
            font-size: 78px;
            margin-bottom: 16px;
        }

        &__payment {
            font-size: 16px;
            font-weight: 600;
        }

        &__welcome {
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 40px;
            text-align: center;
        }

        &__connect {
            padding: 20px;
        }
    }
</style>
